import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AlbumDetail from './Components/AlbumDetail/AlbumDetail';
import Home from './Components/Home/Home';
import Login from './Components/Login/Login';


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/home" component={Home} exact />
        <Route path="/album/:id" component={AlbumDetail} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
